import { SVGProps, memo } from "react";

function DownVote(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 9 10" fill="currentColor" {...props}>
            <path d="M2.055 5.1a.49.49 0 00.495-.485V.485A.49.49 0 013.045 0h2.91a.49.49 0 01.495.486v4.129a.49.49 0 00.495.486h1.559c.441 0 .662.523.35.829L4.85 9.858a.502.502 0 01-.7 0L.146 5.93c-.312-.306-.091-.83.35-.83h1.56z" />
        </svg>
    );
}

const MemoDownVote = memo(DownVote);
export default MemoDownVote;
