import { iconTypeToSVG } from "@/data/fixtures/UI";
import MemoNotSure from "@assets/SVG/NotSure";

/**
 * Maps an iconType to a appropriate icon.
 *
 * @param {string} iconType - A string representing one of the icons in iconTypeToSVG.
 * @param {string} className - A string of class names to style the SVG component returned.
 *
 * @returns a SVG react component for the iconType passed in, or the notSure icon if iconType is unknown.
 */
const SourceIcon = ({ iconType, className }: { iconType: string; className?: string }) => {
    const IconComponent = iconTypeToSVG[iconType] || MemoNotSure;
    return <IconComponent className={className} />;
};

export default SourceIcon;
