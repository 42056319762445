import React, { MouseEventHandler, memo, useCallback, useContext, useRef } from "react";
import MemoDownVote from "@assets/SVG/Vote/downVote";
import MemoUpVote from "@assets/SVG/Vote/upVote";
import { useSnapshot } from "valtio";
import { VoteEnum, userProxy } from "@/store/userStore";
import NewsItemContext from "@app/dashboard/news-feed/news-list/NewsItemContext";
import { getNewsProxy } from "@/store/newsStore";
import { useSelectedLayoutSegment } from "next/navigation";
import Cookies from "js-cookie";
import { debounce } from "lodash";
import { newsVote } from "@/services/news";

interface Props {
    decorated?: boolean;
}

interface ButtonProps {
    dir: VoteEnum;
    color: string;
    isSelected?: boolean;
    read?: boolean;
    voted?: boolean;
    count?: number;
    onClick: MouseEventHandler<HTMLDivElement>;
}

const VoteButton = (props: ButtonProps) => {
    const { color, count, dir, isSelected, read, voted } = props;
    const renderIcon = useCallback(() => {
        const props = {
            fill: voted ? color : "currentColor",
            className:
                `h-[10px] w-[10px]  ` + isSelected
                    ? "text-[#A9C0F0]"
                    : read && !isSelected
                    ? "text-[#505A68]"
                    : "text-[#7A869B]",
        };
        return dir == VoteEnum.Up ? <MemoUpVote {...props} /> : <MemoDownVote {...props} />;
    }, [dir, voted, read, isSelected, color]);

    return (
        <div
            className={`flex cursor-pointer items-center gap-1 px-1 py-1 hover:brightness-75`}
            onClick={props.onClick}
        >
            {renderIcon()}
            <p
                className={`text-xs font-normal ${
                    isSelected ? "text-[#A9C0F0]" : read && !isSelected ? "text-[#505A68]" : "text-[#A1ADC1]"
                }`}
            >
                {count}
            </p>
        </div>
    );
};

const Vote = (props: Props) => {
    const decorated = props.decorated ? "gap-[6px] rounded-[25px] bg-[#252932] py-[6px] pl-2 pr-3" : "";
    const article = useContext(NewsItemContext);

    const prevVote = useSnapshot(userProxy.votes)[article.slug];
    const proxyNews = getNewsProxy(article.slug, article);
    const { upVote, downVote } = useSnapshot(proxyNews);

    const segment = useSelectedLayoutSegment();
    const isSelected = article.slug === decodeURIComponent(segment!);

    const syncState = () => {
        newsVote({
            slug: article.slug,
            jwt: Cookies.get("stytch_session_jwt"),
            vote: userProxy.votes[article.slug] || 0,
        });
    };

    const debounceSyncState = useRef(debounce(syncState, 2000));

    const decreaseNewsVote = (vote: VoteEnum) => {
        switch (vote) {
            case VoteEnum.Up:
                proxyNews.upVote--;
                break;
            case VoteEnum.Down:
                proxyNews.downVote--;
                break;
        }
    };

    const increaseNewsVote = (vote: VoteEnum) => {
        switch (vote) {
            case VoteEnum.Up:
                proxyNews.upVote++;
                break;
            case VoteEnum.Down:
                proxyNews.downVote++;
                break;
        }
    };

    /**
     * Multiple cases
     *  a) No state, user selects Up or Down
     *  b) State is Upvoted
     *      User Downvotes, deletes upvote, downvote selected
     *      User Upvotes again, deletes upvote
     *  c) Same as b) but state is Downvoted
     *      User Upvotes, deletes downvote, upvote selected
     *      User Downvotes again, deletes downvote
     */
    const handleVote = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, vote: VoteEnum) => {
        e.stopPropagation();
        if (!prevVote) {
            userProxy.votes[article.slug] = vote;
            increaseNewsVote(vote);
        } else if (prevVote == vote) {
            userProxy.votes[article.slug] = vote;
            delete userProxy.votes[article.slug];
            decreaseNewsVote(vote);
        } else if (prevVote != vote) {
            userProxy.votes[article.slug] = vote;
            decreaseNewsVote(prevVote);
            increaseNewsVote(vote);
        }
        debounceSyncState.current();
    };

    return (
        <div
            className={
                "flex w-full items-center justify-center gap-1 text-xs font-normal text-[#A1ADC1] " +
                decorated
            }
        >
            <VoteButton
                dir={VoteEnum.Up}
                color="#27AF8F"
                isSelected={isSelected}
                read={article.read}
                voted={prevVote == 1}
                count={upVote || 0}
                onClick={(e) => handleVote(e, VoteEnum.Up)}
            />
            <VoteButton
                dir={VoteEnum.Down}
                color="#C83D4D"
                isSelected={isSelected}
                read={article.read}
                voted={prevVote == -1}
                count={downVote || 0}
                onClick={(e) => handleVote(e, VoteEnum.Down)}
            />
        </div>
    );
};

export default memo(Vote);
