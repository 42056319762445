import { SVGProps, memo } from "react";

function Facebook(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#prefix__clip0_746_30933)">
                <rect width={16} height={16} rx={8} fill={props.fill || "#fff"} />
                <path
                    d="M16 8a8 8 0 00-8-8 8.001 8.001 0 00-1.25 15.904v-5.59H4.722V8h2.03V6.238c0-2.003 1.192-3.113 3.02-3.113.877 0 1.793.158 1.793.158v1.969h-1.008c-.995 0-1.302.618-1.302 1.249V8h2.218l-.355 2.315H9.254v5.589A8.001 8.001 0 0016 8z"
                    fill={props.color || "#1877F2"}
                />
                <path
                    d="M11.112 10.315L11.467 8H9.25V6.5c0-.63.312-1.248 1.302-1.248h1.008V3.283s-.916-.158-1.792-.158c-1.828 0-3.02 1.11-3.02 3.113V8h-2.03v2.315h2.03v5.589A7.84 7.84 0 007.995 16a8.39 8.39 0 001.249-.096v-5.59h1.867z"
                    fill={props.fill || "#fff"}
                />
            </g>
            {/* <defs>
                <clipPath id="prefix__clip0_746_30933">
                    <rect width={16} height={16} rx={8} fill={props.fill || "#fff"} />
                </clipPath>
            </defs> */}
        </svg>
    );
}

const MemoFacebook = memo(Facebook);
export default MemoFacebook;
