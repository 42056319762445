import { Dispatch, SetStateAction, useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export default function Alert({
    content,
    className,
    // showCloseButton = true,
    show,
    setShow,
}: {
    content: string;
    className?: string;
    // showCloseButton?: boolean;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) {
    useEffect(() => {
        setTimeout(function () {
            setShow(false);
        }, 3000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <label
            className={`modal h-24 cursor-pointer items-baseline ${show ? "modal-open" : "destroy-modal"}`}
            style={{
                backgroundColor: "rgba(0, 0, 0, 0)",
            }}
            onClick={() => setShow(false)}
        >
            <label
                className={`${className} scrollbar-hide modal-box relative top-[3.2rem] flex w-[fit-content] max-w-5xl justify-center bg-[#20242C] p-0`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex min-w-[300px] items-center justify-between bg-[#27AF8F] px-3 py-[10px]">
                    <div className="flex h-5 w-5 items-center gap-2 p-0">
                        <div className="flex items-center justify-center">
                            <CheckCircleIcon className="h-4 w-4 text-white" />
                        </div>

                        <p className="whitespace-nowrap text-sm font-normal leading-4 text-white">
                            {content}
                        </p>
                    </div>

                    {/* {showCloseButton && (
                        <div
                            onClick={() => setShow(false)}
                            className="flex h-4 w-4 cursor-pointer items-center justify-center hover:brightness-75"
                        >
                            <XMarkIcon className="h-3 w-3 text-white" />
                        </div>
                    )} */}
                </div>
            </label>
        </label>
    );
}
