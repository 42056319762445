import { proxy } from "valtio";

export enum VoteEnum {
    None = 0,
    Up = 1,
    Down = -1,
}

const user = {
    votes: proxy({}),
};

export const userProxy = proxy(user);
