import { proxy } from "valtio";

export const newsListProxy = proxy();

export const getNewsProxy = (slug, def) => {
    if (!newsListProxy[slug]) {
        newsListProxy[slug] = {
            upVote: def.upVote,
            downVote: def.downVote,
        };
    }
    return newsListProxy[slug];
};
