import { SVGProps, memo } from "react";

function UpVote(props: SVGProps<SVGSVGElement>) {
    const color = props.fill || "currentColor";
    return (
        <svg width="1em" height="1em" viewBox="0 0 9 10" fill={color} {...props}>
            <path d="M6.945 4.9a.49.49 0 00-.495.485v4.13a.49.49 0 01-.495.485h-2.91a.49.49 0 01-.495-.486V5.385a.49.49 0 00-.495-.486H.496c-.441 0-.662-.523-.35-.829L4.15.142a.502.502 0 01.7 0L8.854 4.07c.312.306.091.83-.35.83h-1.56z" />
        </svg>
    );
}

const MemoUpVote = memo(UpVote);
export default MemoUpVote;
