import { News } from "@/types/api";
import { FilterSet } from "@/types/util";

const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
const host = env === "production" ? "prod" : "dev";
const NEWS_API_URL =
    process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
    (env === "production" ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`);

export async function fetchNews(time = String(new Date().getTime())): Promise<News[]> {
    const res = await fetch(`${NEWS_API_URL}/list?` + new URLSearchParams({ time }));
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export async function fetchReadList(jwt: string): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/my-read-list`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export async function fetchVoteList(jwt: string): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/my-vote-list`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export async function fetchNewsItem(slug: string): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/slug/${slug}`);
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export async function markAsRead({ jwt, slug }) {
    const res = await fetch(`${NEWS_API_URL}/mark-as-read/${slug}`, {
        method: "PATCH",
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export async function cloneFilterSet({ filterSet, jwt }: { filterSet: FilterSet; jwt: string }) {
    const res = await fetch(`${NEWS_API_URL}/filters`, {
        body: JSON.stringify(filterSet),
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
    });
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export async function saveFilterSetById({ filterSet, jwt }: { filterSet: FilterSet; jwt: string }) {
    const res = await fetch(`${NEWS_API_URL}/filters/${filterSet.filterIndex}`, {
        body: JSON.stringify(filterSet),
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
    });
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export async function deleteFilterSetById({ filterSet, jwt }: { filterSet: FilterSet; jwt: string }) {
    const res = await fetch(`${NEWS_API_URL}/filters/delete/${filterSet.filterIndex}`, {
        body: JSON.stringify(filterSet),
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
    });

    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export async function fetchMyFilterSets(jwt: string) {
    const res = await fetch(`${NEWS_API_URL}/filters`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export async function newsVote({ slug, jwt, vote }) {
    return await fetch(`${NEWS_API_URL}/slug/${slug}/vote`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ vote }),
    });
}

export async function fetchTwitterHandles() {
    const res = await fetch(`https://twitter.${host}.blockbeat.blockbeat.link/`);
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export async function addTwitterHandle(username: string) {
    const res = await fetch(`https://twitter.${host}.blockbeat.blockbeat.link/${username}`);
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export async function fetchAverageSentiment(): Promise<any> {
    // const res = await fetch(`${NEWS_API_URL}/average-sentiment`);
    // if (!res.ok) {
    //     throw new Error("Failed to fetch data");
    // }
    // return res.json();
}

export async function getNewsByShortlink({ shortlink }): Promise<News> {
    const res = await fetch(`${NEWS_API_URL}/shortlink/${shortlink}`);
    return res.json();
}

export async function createRewardForShortlinkClick({ referredUserId, eventData, shortlink }): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/shortlink/click`, {
        method: "POST",
        body: JSON.stringify({ referredUserId, eventData, shortlink }),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}

export async function createShortlink({ slug, affiliateUserId }): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/shortlink`, {
        method: "POST",
        body: JSON.stringify({ slug, affiliateUserId }),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}

export async function untagNews({ slug, tag }): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/slug/${slug}/untag`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ tag }),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}

export async function tagNews({ slug, tag }): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/slug/${slug}/tag`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ tag }),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}

// export async function scrapeNews(url): Promise<any> {
//     const res = await fetch(`https://q43cu7hse6.execute-api.us-east-1.amazonaws.com/prod/scrape`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ url }),
//     });
//     if (!res.ok) {
//         throw new Error("Failed to post data");
//     }
//     return res.json();
// }

export async function createNewPost(news: any): Promise<any> {
    const res = await fetch(`https://d5fe2ie23a.execute-api.us-east-1.amazonaws.com/prod/new-post`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(news),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}

// POST request to news API to create a new keyword
export async function createKeyword(list = "other", value: string, score: number): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/keywords/add`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ key: list, item: { score, value } }),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}
